<template>
  <div class="combinationQuestion">
    <!-- 组合题号弹窗 -->
    <el-dialog
      title="组合题号"
      :visible.sync="combinatorialTitleVisible"
      width="850px"
      class="el-transfer"
    >
      <div class="transfer">
        <div class="left-panel">
          <div class="panel-1">
            <p class="panel-header">试题题号列表</p>
            <div class="panel-body">
              <el-checkbox-group v-model="leftCheckedItems2">
                <el-checkbox
                  v-for="item in transferLeft2Data"
                  :key="item"
                  :label="item"
                  >{{ item }}</el-checkbox
                >
              </el-checkbox-group>
            </div>
          </div>
          <div class="panel-1">
            <p class="panel-header">成绩题号列表</p>
            <div class="panel-body">
              <el-checkbox-group v-model="leftCheckedItems1">
                <el-checkbox
                  v-for="item in transferLeft1Data"
                  :key="item"
                  :label="item"
                  >{{ item }}</el-checkbox
                >
              </el-checkbox-group>
            </div>
          </div>
        </div>
        <div class="buttons">
          <el-button
            type="primary"
            class="el-icon-arrow-left"
            plain
            @click="dataShiftLeft"
          ></el-button>
          <el-button
            type="primary"
            class="el-icon-arrow-right"
            plain
            @click="dataShiftRight"
          ></el-button>
          <el-button class="auto-combine" type="primary" @click="autoCombine">
            自动组合
          </el-button>
          <el-button class="auto-combine" type="primary" @click="fuzzyQuery">
            模糊匹配
          </el-button>
        </div>
        <div class="right-panel">
          <div class="panel-1" style="width: 100%">
            <p class="panel-header">
              <!-- <el-checkbox
                v-model="checkedAll"
                style="margin-right: 10px"
                @change="checkedAllChange"
              >
              </el-checkbox> -->
              <el-button type="text" @click="checkedAllChange">全选</el-button>
              题号映射汇总
            </p>
            <div class="panel-body">
              <el-checkbox-group v-model="rightCheckedItems">
                <el-checkbox
                  v-for="item in transferRightData"
                  :key="item"
                  :label="item"
                  >{{ item }}</el-checkbox
                >
              </el-checkbox-group>
            </div>
          </div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          :loading="combineBtnDisabled"
          @click="questionOrderFormSubmit()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  delArrFromArr,
  pushArrToArrByUnique,
  //   fileDownloadByUrl,
} from "@/core/util/util";
import {
  //   fetchList,
  getQuestionNumsObjV2,
  putQuestionNumsObj,
  //   clearByPaperId,
  //   downloadTemplate,
  //   exportAll,
} from "@/core/api/exam/examstudentscore";
export default {
  name: "CombinationQuestion",
  data() {
    return {
      // 组合题号
      checkedAll: false,
      combinatorialTitleVisible: false,
      combineBtnDisabled: false,
      leftCheckedItems2: [],
      transferLeft2Data: [],
      leftCheckedItems1: [],
      transferLeft1Data: [],
      rightCheckedItems: [],
      transferRightData: [],
      // 组合题号 end
    };
  },
  created() {},
  methods: {
    checkedAllChange() {
      if (this.transferRightData.length > this.rightCheckedItems.length) {
        this.rightCheckedItems = this.transferRightData;
      } else {
        this.rightCheckedItems = [];
      }
    },
    // 组合题号 start
    // 展示组合穿梭框
    combinatorialQuestionOrder(indexPaperId) {
      this.indexPaperId = indexPaperId;
      this.combinatorialTitleVisible = true;
      getQuestionNumsObjV2({ id: this.indexPaperId }).then((response) => {
        this.transferLeft1Data = response.data.data.scoreNumberList;
        this.transferLeft2Data = response.data.data.questionNumberList;
        this.transferRightData = response.data.data.scoreNumberIdsList;
        this.leftCheckedItems1.length = 0;
        this.leftCheckedItems2.length = 0;
        this.rightCheckedItems.length = 0;
      });
    },
    // 组合框提交
    questionOrderFormSubmit() {
      if (
        this.transferLeft1Data.length > 0 ||
        this.transferLeft2Data.length > 0
      ) {
        this.$message.warning("左侧成绩和试题题号必须全部被组合");
        return;
      }
      if (this.transferRightData.length <= 0) {
        this.$message.warning("请确保至少需要有一条题号组合数据");
        return;
      }
      let rightDataObj = {
        examPaperId: this.indexPaperId,
        questionCombinNumbers: this.transferRightData.join("###"),
      };
      this.combineBtnDisabled = true;
      putQuestionNumsObj(rightDataObj)
        .then((response) => {
          if (!response.data.msg) {
            this.$message.success("题号组合映射处理成功");
            this.combinatorialTitleVisible = false;
            this.combineBtnDisabled = false;
          } else {
            this.$message.warning(response.data.msg);
            this.combineBtnDisabled = false;
          }
        })
        .catch(() => {
          this.combineBtnDisabled = false;
        });
    },
    // 左移
    dataShiftLeft() {
      if (this.rightCheckedItems.length <= 0) {
        return;
      }
      // 处理右侧数组
      delArrFromArr(this.transferRightData, this.rightCheckedItems);
      // 处理左侧数组
      for (let index in this.rightCheckedItems) {
        let tempRightData = this.rightCheckedItems[index];
        let tempArr = tempRightData.split("|");
        if (tempArr.length < 2) {
          continue;
        }
        pushArrToArrByUnique(this.transferLeft1Data, tempArr[0].split(";"));
        pushArrToArrByUnique(this.transferLeft2Data, tempArr[1].split(";"));
      }
      // console.log(this.rightCheckedItems);
      this.rightCheckedItems.map((item) => {
        this.transferRightData = this.transferRightData.filter(
          (index) => index != item
        );
      });
      // .map()
      // console.log(this.transferRightData);
      // this.rightCheckedItems = [];
      // this.transferRightData = [];
    },
    // 右移
    dataShiftRight() {
      let left1Length = this.leftCheckedItems1.length;
      let left2Length = this.leftCheckedItems2.length;
      if (left1Length == 0 || left2Length == 0) {
        this.$message.warning("请左侧列表每列至少选择一个");
        return;
      } else if (left1Length != 1 && left2Length != 1) {
        this.$message.warning("请左侧列表其中一列只能选择一个");
        return;
      }
      this.selectDataTransfer();
    },
    selectDataTransfer() {
      // 添加右侧数据
      let leftCheckedItems1Data = this.leftCheckedItems1.join(";");
      let leftCheckedItems2Data = this.leftCheckedItems2.join(";");
      let rightCombinData = leftCheckedItems1Data + "|" + leftCheckedItems2Data;
      this.transferRightData.push(rightCombinData);
      // 删除左侧数据1
      delArrFromArr(this.transferLeft1Data, this.leftCheckedItems1);
      // 删除左侧数据2
      delArrFromArr(this.transferLeft2Data, this.leftCheckedItems2);
      // 清除选择数据
      this.leftCheckedItems1.length = 0;
      this.leftCheckedItems2.length = 0;
    },
    fuzzyQuery() {
      /**
       * this.transferLeft1Data--题号数据源
       * this.leftCheckedItems1--题号已选项
       * this.transferLeft2Data--试题数据源
       * this.leftCheckedItems2--试题已选项
       */
      // 避免重复添加
      this.leftCheckedItems2 = [];
      this.leftCheckedItems1 = [];
      let indexArr1 = [];
      this.transferLeft2Data.forEach((item) => {
        this.transferLeft1Data.forEach((index) => {
          if (item.indexOf(index) != -1 || index.indexOf(item) != -1) {
            if (indexArr1.indexOf(item) != -1) return;
            indexArr1.push(item);
            this.leftCheckedItems2.push(item);
            this.leftCheckedItems1.push(index);
            this.selectDataTransfer();
            this.fuzzyQuery();
          }
        });
      });
    },
    //自动组合
    autoCombine() {
      /**
       * this.transferLeft1Data--题号数据源
       * this.leftCheckedItems1--题号已选项
       * this.transferLeft2Data--试题数据源
       * this.leftCheckedItems2--试题已选项
       */
      //   leftCheckedItems1
      this.leftCheckedItems2 = [];
      this.leftCheckedItems1 = [];
      this.transferLeft2Data.forEach((item) => {
        this.transferLeft1Data.forEach((index) => {
          if (item == index) {
            this.leftCheckedItems2.push(item);
            this.leftCheckedItems1.push(index);
            this.selectDataTransfer();
            this.autoCombine();
          }
        });
      });
    },
    // 组合题号 end
  },
};
</script>
<style lang="scss" scoped>
.combinationQuestion {
  .el-transfer {
    .transfer {
      // padding: 0 20px;
      display: flex;
      align-items: center;
      .left-panel,
      .right-panel {
        overflow: hidden;
        background: #fff;
        display: inline-block;
        vertical-align: middle;
        width: 400px;
        max-height: 100%;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        display: flex;
      }
      .left-panel {
        .panel-1 {
          width: 50%;
        }
      }
      .right-panel {
        width: 200px;
      }
      .auto-combine {
        margin: 10px auto;
        display: block;
      }
      .el-icon-arrow-left,
      .el-icon-arrow-right {
        padding: 8px;
        font-size: 21px;
      }
      //  el-icon-arrow-left el-icon-arrow-right
      .buttons {
        // width: 200px;
        text-align: center;
        // margin-right: 10px;
        // margin-top: 78px;
        padding: 0 24px 0 14px;
      }
      .panel-1 {
        margin-right: 10px;
        border: 1px solid #ebeef5;
        border-radius: 2px;
      }
      .panel-header {
        height: 40px;
        line-height: 40px;
        background: #f5f7fa;
        padding-left: 15px;
        border-bottom: 1px solid #ebeef5;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;

        .is-checked {
          color: #606266;
        }
      }
      .panel-body {
        .el-checkbox-group {
          height: 338px;
          overflow: auto;
          label {
            width: 82%;
            padding-left: 15px;
          }
        }
      }
    }
  }
}
</style>
